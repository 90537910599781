<template lang="pug">
.profile-card
    .card(v-if="profile.profile")
        .card-body
            p.m-0
                UserName(:id="id" style="font-weight: bold")
            p.small {{profile.profile.description}}
            .row
                .col.s-6
                    FollowButton(:profile="profile")
                .col.s-6
                    span.material-icons hail
                    span {{Object.keys(profile.subscribed).length}}
</template>

<script>
import API from "../API.js";
import ImageCircle from "./fundamentals/ImageCircle.vue";
import UserName from "./UserName.vue";
import FollowButton from "./fundamentals/FollowButton.vue";
import { UserStore } from "./../UserStore.js";

export default {
  name: "GroupCard",
  props: ["id"],
  components: {ImageCircle, UserName, FollowButton},
  data() {
    return {
      profile: {},
      UserStore
    };
  },
  mounted() {
    this.refresh(this.id);
  },
  methods: {
    goToProfile() {
      this.$router.push("/feed/" + this.id);
    },
    refresh(id) {
      API.getUserProfile(id).then((data) => {
          this.profile = data;
      });
    },
  },
};
</script>

<style lang='sass' scoped>
</style>