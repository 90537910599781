<template lang="pug">
.groups-component.container
  .form-floating(style="margin-bottom:15px")
    input.form-control(placeholder="search" v-model="queryStr" v-on:input="onQueryChange")
    label 
      span.material-icons search
      | {{ $t("message.search") }}
  .recent-groups.row.text-start
    .group.col-sm-6(v-for="g in groups" style="margin-bottom:15px" :key="g._id")
        GroupCard(:id="g._id" :key="g._id").m-1
  .new-group.card
    .card-body
        h4 {{ $t("message.title") }}
        form#new-group-form(@submit.prevent)
            .row
                .col-6.form-floating           
                    input( placeholder='Name' v-model="title").form-control#NameInput
                    label(for="NameInput") {{ $t("message.title") }}
                .col-6.form-floating   
                    input(placeholder='Last Name' v-model="subtitle").form-control#LastNameInput
                    label(for="LastNameInput") {{ $t("message.subtitle") }}
            br
            .row
                .col-12.form-floating
                    textarea.form-control#DescriptionInput(placeholder="Describe yourself" v-model="description")
                    label(for="DescriptionInput") Describe this group
            br
            .row
                .col-12.text-start
                    .form-check.form-switch
                        input.form-check-input(type="checkbox" v-model="isPrivate")
                        label.form-check-label {{ $t("message.privateGroup") }}
            .text-center
                button.btn.btn-primary(@click="register") {{ $t("message.register") }}
</template>

<script>
import UserName from "./../components/UserName.vue";
import GroupCard from "./../components/GroupsCard.vue";
import API from "./../API.js";

export default {
    components:{
        UserName,
        GroupCard
    },
    data(){
        return {
            title: '',
            subtitle: '',
            description: '',
            isPrivate: false,
            groups: [],
            queryStr: '',
            timer: 0,
        }
    },
    methods:{
        register(){
            if(this.title + this.subtitle + this.description === '') return 0;
            API.newGroup(this.title, this.subtitle, this.description, this.isPrivate)
                .then(()=>{
                    this.title = "";
                    this.subtitle = "";
                    this.description = "";
                });
        },
        onQueryChange(){
            console.log("Change to", this.queryStr)
            if(this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(()=>{
                if(!this.queryStr) {
                    return API.getRecentGroups().then((data)=>{
                        this.groups = data.groups;
                    })
                }
                API.searchGroups(this.queryStr).then((data)=>{
                    this.groups = data.groups;
                })
            }, 300)
        }
    },
    mounted(){
        API.getRecentGroups().then((data)=>{
            this.groups = data.groups;
        })
    }
};
</script>

<style>
</style>